<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/home-dna.jpg')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          width="100%"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <base-heading title="DISCOVER THE FUTURE OF CELL CULTIVATION" />

              <base-body class="text-justify">
                <span class="subheading">
                  We develop functional biopolymers, also known as aptamers. They are able to recognize and bind to target structures in a highly specific manner.
                  In addition, our unique aptamers are also bioactive. This makes them robust biotechnological raw materials and novel drug candidates.
                </span>
                <p class="font-weight-bold">
                  Robust, Versatile, Clean and Lean - Let us convince you.
                </p>
              </base-body>

              <div
                :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start ' : 'align-center '"
                class="d-flex flex-wrap"
              >
                <base-btn
                  to="Products"
                >
                  Discover More
                </base-btn>

                <span class="font-weight-bold ml-6 mr-4 my-4">or</span>

                <base-btn
                  :ripple="false"
                  class="pa-1"
                  height="auto"
                  text
                  to="contact-us"
                >
                  Get In Touch Now
                </base-btn>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="4"
              :offset="$vuetify.breakpoint.smAndDown ? '0' : '2'"
            >
              <div
                :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start my-2' : 'align-center '"
                class="containerYT"
              >
                <iframe
                  :width="width"
                  :height="height"
                  :src="video"
                  title="Aptamimetics in 120 Seconds (German)"
                  frameborder="0"
                  class="videoYT"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                />
              </div>
              <p class="font-weight-bold dense">
                Watch us explain Aptamimetics in 120 Seconds. (German)
              </p>
            </v-col>
          </v-row>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    data: () => ({
      video: 'https://www.youtube-nocookie.com/embed/HJMX-0lpuW8?si=pnp4aG2uBa2_53WA', // Replace with your YouTube video ID
      width: 560, // Set your preferred width
      height: 315, // Set your preferred height
    }),

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>

<style lang="sass">
  .containerYT
    position: relative
    width: 100%
    height: 0
    padding-bottom: 56.25%

  .videoYT
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
</style>
